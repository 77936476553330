body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(to bottom, #dff0ff, #edf6ff);
  overflow-x: hidden;
}

.app-container {
  text-align: center;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  color: #223e87;
  margin-bottom: 0.5rem;
}

p.subtitle {
  color: #305fa5;
  margin-bottom: 3rem;
}

.timeline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1rem;
  position: relative;
}

.step-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  min-width: 250px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  text-align: center;
}

.step-card h3 {
  color: #223e87;
  margin-top: 0.5rem;
}

.step-card p {
  color: #2c3e50;
  font-size: 0.95rem;
  margin-top: 0.5rem;
}

.arrow {
  font-size: 2rem;
  color: #b4c8f0;
  user-select: none;
}

@media (max-width: 768px) {
  .timeline-container {
    flex-direction: column;
    gap: 2rem;
  }

  .arrow {
    transform: rotate(90deg);
  }
}
